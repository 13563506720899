import React from 'react'
import ReactDOM from 'react-dom/client'
import reportWebVitals from './reportWebVitals'

import './data/styles/index.scss'
import { Provider } from 'react-redux'
import { store } from './data/store/index' // Import as default
import App from './App'
import { ConfigProvider } from 'antd'

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <ConfigProvider
    theme={{
      token: {
        colorPrimary: process.env.REACT_APP_PRIMARY_COLOR || '#A224E9',
        borderColor: process.env.REACT_APP_PRIMARY_COLOR || "#A224E9",
      },
    }}
  >
    <Provider store={store}>
      <App />
    </Provider>
  </ConfigProvider>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
