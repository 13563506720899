import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import {
  Form,
  Input,
  DatePicker,
  Button,
  Row,
  Col,
  Card,
  Radio,
  Checkbox,
  Select,
  InputNumber,
  message,
  Tag,
} from "antd"
import dayjs from "dayjs"
import { setFormData } from "../../../data/store/formSlice/index"
import "../../../data/styles/dynamicForm.scss"
import ShowSuccess from "../ShowSuccess"

const DynamicForm = ({ payload, onSubmit, initialData = {} }) => {
  const [form] = Form.useForm()
  const dispatch = useDispatch()
  const formData = useSelector((state) => state.form.formData)
  const [filteredOptions, setFilteredOptions] = useState({})
  const [sectionValidation, setSectionValidation] = useState({})
  const [showSuccessMessage, setShowSuccessMessage] = useState(false)
  const [defaultMessage, setDefaultMessage] = useState("")
  const [payloadState, setPayloadState] = useState(payload)

  const sectionColor =
    process.env.REACT_APP_SECTION_COLOR !== undefined
      ? process.env.REACT_APP_SECTION_COLOR
      : "#f1f3f6"

  useEffect(() => {
    const initializeOptions = () => {
      const savedFormData =
        JSON.parse(localStorage.getItem("unsavedFormData")) || {}
      const initialValues = {
        ...(savedFormData || initialData || formData || {}),
      }

      let initialSectionValidation = {}
      if (Array.isArray(payload?.sections)) {
        payload.sections.forEach((section) => {
          let sectionSum = 0
          if (Array.isArray(section.fields)) {
            section.fields.forEach((field) => {
              if (field.props?.defaultValue !== undefined) {
                if (field.type === "date") {
                  const parseDate = (dateValue) => {
                    if (!dateValue || dateValue === "") {
                      return undefined
                    }
                    const parsedDate = dayjs(dateValue, "YYYY-MM-DD", true)
                    return parsedDate.isValid() ? parsedDate : undefined
                  }
                  if (
                    !Object.prototype.hasOwnProperty.call(
                      field.props,
                      "defaultValue"
                    )
                  ) {
                    initialValues[field.props.name] = undefined
                  } else {
                    initialValues[field.props.name] = parseDate(
                      field.props.defaultValue
                    )
                  }
                  if (savedFormData[field.props.name]) {
                    initialValues[field.props.name] = parseDate(
                      savedFormData[field.props.name]
                    )
                  }
                } else if (
                  !Object.prototype.hasOwnProperty.call(
                    initialValues,
                    field.props.name
                  )
                ) {
                  initialValues[field.props.name] = field.props.defaultValue
                }
              }
              if (field.includeSum && initialValues[field.props.name]) {
                sectionSum += Number(initialValues[field.props.name])
              }
            })
          }
          initialSectionValidation[section.title] = {
            currentSum: sectionSum,
            totalLimit: section.totalLimit || 0,
          }
        })
      }

      // Ensure all date values in `initialValues` are converted to `dayjs` objects
      Object.keys(initialValues).forEach((key) => {
        const value = initialValues[key]
        if (typeof value === "string") {
          const dateValue = dayjs(value, "YYYY-MM-DD", true)
          initialValues[key] = dateValue.isValid() ? dateValue : value
        }
      })

      form.setFieldsValue(initialValues)
      setSectionValidation(initialSectionValidation)
    }

    initializeOptions()
  }, [payload, initialData, formData, form])

  const applyDependencyLogic = (
    tobeChangeField,
    dependingField,
    filteringKey = "value"
  ) => {
    const { props, dependableValue } = tobeChangeField
    const { props: dependingProps } = dependingField

    switch (tobeChangeField.type) {
      case "text":
        if (!dependableValue) return { ...tobeChangeField }
        let tobeUpdatedValue = dependableValue[dependingProps?.[filteringKey]]
        return {
          ...tobeChangeField,
          props: {
            ...props,
            value: tobeUpdatedValue,
          },
        }

      case "select": {
        const filteredOptions = props.options.map((option) => {
          if (
            !option?.parent ||
            dependingProps?.[filteringKey] === option.parent
          ) {
            return { ...option, notVisible: false }
          }
          return { ...option, notVisible: true }
        })
        console.log(filteredOptions, "filtered")
        const validValues = filteredOptions

          .filter((option) => !option.notVisible)
          .map((option) => option.value)

        return {
          ...tobeChangeField,
          props: {
            ...props,
            options: filteredOptions,
            ...(validValues.length > 0 && {
              value: validValues[0],
            }),
          },
        }
      }

      case "checkbox": {
        const validOptions = props.options?.filter(
          (option) => option?.parent === dependingProps?.[filteringKey]
        )
        const validValues = validOptions.map((option) => option.value)
        const existingValues = Array.isArray(props.value)
          ? props.value.filter((value) => validValues.includes(value))
          : []
        const newValues = [...new Set([...existingValues, ...validValues])]

        return {
          ...tobeChangeField,
          props: {
            ...props,
            value: newValues,
          },
        }
      }

      case "radio":
        const toBeSelectedOption = props.options?.find(
          (option) => option?.parent === dependingProps?.[filteringKey]
        )

        return {
          ...tobeChangeField,
          props: {
            ...props,
            value: toBeSelectedOption?.value,
          },
        }

      case "textarea":
      case "input":
      case "inputNumber":
        return {
          ...tobeChangeField,
          props: {
            ...props,
            value: dependableValue[dependingProps?.[filteringKey]],
          },
        }
      case "date":
        return {
          ...tobeChangeField,
          props: {
            ...props,
            value: dayjs(dependableValue[dependingProps?.[filteringKey]]),
          },
        }

      default:
        return { ...tobeChangeField }
    }
  }

  const handleSelectChange = (field, fieldIdx, sectionIdx) => {
    const { sections } = payloadState
    let currentSection = sections[sectionIdx]
    const currentFields = currentSection?.fields
    if (currentFields) {
      const updatedDependentFields = currentFields.map((currField) => {
        if (
          field.props.name &&
          currField.dependentOn &&
          currField.dependentOn === field?.props?.name
        ) {
          const updatedField = applyDependencyLogic(currField, field)
          form.setFieldsValue({
            [updatedField?.props?.name]: updatedField.props.value,
          })
          return updatedField
        }
        return currField
      })
      currentSection = {
        ...currentSection,
        fields: updatedDependentFields,
      }

      const updatedSections = [...sections]
      updatedSections[sectionIdx] = currentSection

      setPayloadState({ ...payloadState, sections: updatedSections })
    }
  }

  const handleValuesChange = (changedValues, allValues) => {
    const sectionValidationState = { ...sectionValidation }

    if (Array.isArray(payloadState?.sections)) {
      payloadState.sections.forEach((section) => {
        let sectionSum = 0

        if (Array.isArray(section.fields)) {
          section.fields.forEach((field) => {
            if (field.includeSum && allValues[field.props.name]) {
              sectionSum += Number(allValues[field.props.name])
            }
            if (field.dependentOn) {
              const dependingField = section.fields.find(
                (f) => f.props.name === field.dependentOn
              )
            }
          })
        }
        sectionValidationState[section.title] = {
          currentSum: sectionSum,
          totalLimit: section.totalLimit || 0,
        }
      })
    }
    setSectionValidation(sectionValidationState)
    const formattedValues = { ...allValues }
    Object.keys(allValues).forEach((key) => {
      const value = allValues[key]
      if (typeof value === "object" && value?.$isDayjsObject) {
        formattedValues[key] = dayjs(value).format("YYYY-MM-DD")
      }
    })

    localStorage.setItem("unsavedFormData", JSON.stringify(formattedValues))
  }

  const handleFinish = async () => {
    try {
      const values = await form.validateFields()
      const formattedValues = { ...values }

      Object.keys(values).forEach((key) => {
        const value = values[key]
        if (typeof value === "object" && value?.$isDayjsObject) {
          formattedValues[key] = dayjs(value).format("YYYY-MM-DD")
        }
      })

      let isSumValid = true
      if (Array.isArray(payload?.sections)) {
        payload.sections.forEach((section) => {
          const sectionTitle = section.title
          const currentSum = sectionValidation[sectionTitle]?.currentSum || 0
          const totalLimit = section.totalLimit

          if (totalLimit !== undefined && currentSum !== totalLimit) {
            isSumValid = false
            message.error(
              `The total allocation for section "${sectionTitle}" is ${currentSum}. It must equal ${totalLimit}.`
            )
          }
        })
      }

      if (!isSumValid) {
        return
      }

      dispatch(setFormData(values))
      localStorage.setItem("savedFormData", JSON.stringify(formattedValues))

      const dataToSend = {
        sessionId: new URLSearchParams(window.location.search).get("sessionId"),
        uniqueId: new URLSearchParams(window.location.search).get("uniqueId"),
        formData: formattedValues,
      }

      const API_URL = `${process.env.REACT_APP_BASE_URL}/brand/indiafirst-iva/dynamicform/submitformdetails`

      try {
        const response = await fetch(API_URL, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(dataToSend),
        })

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`)
        }

        const responseData = await response.json()

        setDefaultMessage(
          responseData.defaultMessage ||
            "Your data has been submitted successfully!"
        )
        setShowSuccessMessage(true)

        if (payload.waMeUrl) {
          setTimeout(() => {
            window.open(payload.waMeUrl, "_blank")
          }, 2000)
        }
      } catch (error) {
        message.error("Something went wrong")
      }
      form.resetFields()
      localStorage.removeItem("unsavedFormData")
    } catch (error) {
      console.error("Validation failed:", error)
    }
  }

  const renderField = (field, fieldIdx, sectionIdx) => {
    const { type, props } = field

    const getValidationRules = (field) => {
      const rules = []
      if (field.props.required) {
        rules.push({ required: true, message: `${field.label} is required` })
      }
      if (field.props.regex) {
        const regex = new RegExp(field.props.regex)
        rules.push({
          validator: (_, value) => {
            if (!value || regex.test(value)) {
              return Promise.resolve()
            }
            return Promise.reject(field.props.regexMessage || "Invalid input")
          },
        })
      }

      return rules
    }

    const renderInputField = () => {
      const { name, disable, ...restProps } = props

      switch (type) {
        case "text":
        case "input":
        case "textarea":
          return (
            <Input
              {...restProps}
              disabled={disable}
              value={form.getFieldValue(name)}
              style={{ width: "100%" }}
            />
          )
        case "date":
          const formattedDate = form.getFieldValue(name)
            ? dayjs(
                form.getFieldValue(name),
                field.props.format || "YYYY-MM-DD"
              )
            : undefined

          return (
            <DatePicker
              {...restProps}
              style={{ width: "100%" }}
              disabled={disable}
              value={
                formattedDate
                  ? dayjs(formattedDate, field.props.format || "YYYY-MM-DD")
                  : undefined
              }
              disabledDate={(c) =>
                c && field.disabledTimestamp && field.beforeDisabledTimestamp
                  ? c.valueOf() > field.disabledTimestamp ||
                    field.beforeDisabledTimestamp > c.valueOf()
                  : field.disabledTimestamp
                  ? c.valueOf() > field.disabledTimestamp
                  : field.beforeDisabledTimestamp
                  ? field.beforeDisabledTimestamp > c.valueOf()
                  : null
              }
              defaultValue={
                restProps?.defaultValue
                  ? dayjs(restProps.defaultValue, "YYYY-MM-DD")
                  : undefined
              }
              format={field?.props?.format || "YYYY-MM-DD"}
              onChange={(date) => {
                form.setFieldsValue({ [name]: date })
              }}
              inputReadOnly
            />
          )

        case "radio":
          return (
            <Radio.Group
              {...restProps}
              disabled={disable}
              className="custom-radio-group"
              value={form.getFieldValue(name)}
              optionType="button"
              buttonStyle="solid"
            >
              {Array.isArray(props?.options) &&
                props.options.map((option, idx) => (
                  <Radio key={idx} value={option.value}>
                    {option.label}
                  </Radio>
                ))}
            </Radio.Group>
          )
        case "checkbox":
          return (
            <Checkbox.Group
              {...restProps}
              disabled={disable}
              className="custom-checkbox-group"
              value={form.getFieldValue(name)}
            >
              {Array.isArray(props?.options) &&
                props.options.map((option, idx) => (
                  <Checkbox key={idx} value={option.value}>
                    {option.label}
                  </Checkbox>
                ))}
            </Checkbox.Group>
          )
        case "select":
          return (
            <Select
              {...restProps}
              disabled={disable}
              style={{ width: "100%" }}
              value={form.getFieldValue(name)}
              onChange={(value) => {
                const updatedField = {
                  ...field,
                  props: {
                    ...field.props,
                    value,
                  },
                }
                handleSelectChange(updatedField, fieldIdx, sectionIdx)
                form.setFieldsValue({ [name]: value })
              }}
              showSearch
              placeholder={props.placeholder || "Select an option"}
              optionFilterProp="children"
              filterOption={(input, option) =>
                option?.children.toLowerCase().includes(input.toLowerCase())
              }
            >
              {props.options
                .filter((option) => !option.notVisible)
                .map((option) => (
                  <Select.Option key={option.value} value={option.value}>
                    {option.label}
                  </Select.Option>
                ))}
            </Select>
          )
        case "inputNumber":
          return (
            <InputNumber
              {...restProps}
              disabled={disable}
              value={form.getFieldValue(name)}
              min={0}
              max={payload.totalLimit}
              formatter={(value) => `${value}%`}
              parser={(value) => value.replace("%", "")}
            />
          )
        default:
          return null
      }
    }

    const itemClassName = type === "select" ? "custom-form-item-select" : ""
    return (
      <Form.Item
        name={props.name}
        label={
          <div>
            <span>{field.label}</span>
            {field.description && (
              <div
                style={{
                  fontSize: "10px",
                  color: "#888",
                  marginTop: "1px",
                }}
              >
                {field.description}
              </div>
            )}
          </div>
        }
        rules={getValidationRules(field)}
        className={itemClassName}
      >
        {renderInputField()}
      </Form.Item>
    )
  }

  return (
    <div className="dynamic-form-container">
      <Form
        form={form}
        layout="vertical"
        onFinish={handleFinish}
        onValuesChange={handleValuesChange}
        className="dynamic-form"
      >
        {Array.isArray(payloadState?.sections) &&
          payloadState.sections.map((section, sectionIdx) => (
            <Card
              key={sectionIdx}
              title={section.title}
              className="custom-card"
              style={{ backgroundColor: sectionColor || "#f1f3f6" }}
              extra={
                section.totalLimit ? (
                  <Tag
                    color="default"
                    size="large"
                    style={{ fontSize: "16px", padding: "5px 10px" }}
                  >
                    Total Limit: {section.totalLimit}
                  </Tag>
                ) : null
              }
            >
              {section.description && <p>{section.description}</p>}
              <Row gutter={[16, 0]}>
                {Array.isArray(section.fields) &&
                  section.fields.map((field, idx) => (
                    <Col xs={24} sm={24} md={12} lg={12} xl={12} key={idx}>
                      {renderField(field, idx, sectionIdx)}
                    </Col>
                  ))}
              </Row>
            </Card>
          ))}
        <Row justify="center">
          <Button
            type="primary"
            htmlType="submit"
            size="large"
            style={{
              backgroundColor:
                process.env.REACT_APP_PRIMARY_COLOR || "$color-primary",
              borderColor:
                process.env.REACT_APP_PRIMARY_COLOR || "$color-primary",
            }}
            className="custom-save-button"
          >
            Final Submit
          </Button>
        </Row>
      </Form>
      {showSuccessMessage && <ShowSuccess message={defaultMessage} />}
    </div>
  )
}

export default DynamicForm
