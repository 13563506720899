import React from "react"
const  Empty = ({ height, width, color }) => {
   const primaryColor = process.env.REACT_APP_PRIMARY_COLOR || color || '#000'
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      data-name='Layer 1'
      width={width}
      height={height}
      viewBox='0 0 1022.7 785.81'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      role='img'
      primaryColor={process.env.REACT_APP_PRIMARY_COLOR || color}
    >
      <path
        d='M578.47505,103.95771l-23.06843,12.58664L271.19846,271.61447,248.13,284.2011a48.1793,48.1793,0,0,0-19.1955,65.29607L440.57765,737.39072a48.17922,48.17922,0,0,0,65.296,19.19561l.05958-.03251L836.15907,576.37545l.05958-.03251a48.17924,48.17924,0,0,0,19.19553-65.296L643.77106,123.15338A48.17929,48.17929,0,0,0,578.47505,103.95771Z'
        transform='translate(-223.06266 -98.08587)'
        fill='#f2f2f2'
      />
      <path
        d='M585.11115,116.11916l-27.323,14.908L282.08828,281.455,254.7657,296.36278a34.30947,34.30947,0,0,0-13.66965,46.4988L452.73916,730.75513a34.30947,34.30947,0,0,0,46.4988,13.66952l.05958-.0325L829.5234,564.21377l.06-.03274a34.30935,34.30935,0,0,0,13.66926-46.49851L631.60954,129.789A34.30936,34.30936,0,0,0,585.11115,116.11916Z'
        transform='translate(-223.06266 -98.08587)'
        fill='#fff'
      />
      <path
        d='M589.66653,236.52147,466.505,303.72109a8.01411,8.01411,0,1,1-7.677-14.07012l123.16157-67.19962a8.01411,8.01411,0,1,1,7.677,14.07012Z'
        transform='translate(-223.06266 -98.08587)'
        fill='#f2f2f2'
      />
      <path
        d='M631.641,244.43106,479.45984,327.46442a8.01411,8.01411,0,0,1-7.677-14.07012l152.18119-83.03336a8.01411,8.01411,0,1,1,7.677,14.07012Z'
        transform='translate(-223.06266 -98.08587)'
        fill='#f2f2f2'
      />
      <path
        d='M415.87223,275.74837l-113.5479,61.95419a3.84082,3.84082,0,0,0-1.53151,5.21006L349.14436,431.53a3.84075,3.84075,0,0,0,5.21,1.5317l113.5479-61.95419a3.84075,3.84075,0,0,0,1.53153-5.21l-48.35154-88.61735A3.84081,3.84081,0,0,0,415.87223,275.74837Z'
        transform='translate(-223.06266 -98.08587)'
        fill='#f2f2f2'
      />
      <path
        d='M650.7763,348.96263,483.723,440.11051a8.01411,8.01411,0,1,1-7.677-14.07012l167.05327-91.14788a8.01411,8.01411,0,1,1,7.677,14.07012Z'
        transform='translate(-223.06266 -98.08587)'
        fill='#f2f2f2'
      />
      <path
        d='M692.7508,356.87223,496.67791,463.85384a8.01411,8.01411,0,0,1-7.677-14.07012L685.07384,342.80211a8.01411,8.01411,0,1,1,7.677,14.07012Z'
        transform='translate(-223.06266 -98.08587)'
        fill='#f2f2f2'
      />
      <circle cx='197.03853' cy='382.67177' r='34' fill='#f2f2f2' />
      <path
        d='M928.81234,263.78816H552.494a48.17927,48.17927,0,0,0-48.125,48.12512V753.78907a48.17922,48.17922,0,0,0,48.125,48.12506H928.81234a48.17922,48.17922,0,0,0,48.125-48.12506V311.91328A48.17927,48.17927,0,0,0,928.81234,263.78816Z'
        transform='translate(-223.06266 -98.08587)'
        fill='#e6e6e6'
      />
      <path
        d='M928.81283,277.64235H552.494a34.30947,34.30947,0,0,0-34.271,34.27093V753.78907A34.30947,34.30947,0,0,0,552.494,788.06H928.81283a34.30936,34.30936,0,0,0,34.27051-34.27088V311.91328A34.30937,34.30937,0,0,0,928.81283,277.64235Z'
        transform='translate(-223.06266 -98.08587)'
        fill='#fff'
      />
      <path
        d='M875.14319,385.51745H734.84151a8.01411,8.01411,0,0,1,0-16.02823H875.14319a8.01412,8.01412,0,1,1,0,16.02823Z'
        transform='translate(-223.06266 -98.08587)'
        fill={primaryColor}
      />
      <path
        d='M908.20141,412.56508H734.84151a8.01411,8.01411,0,1,1,0-16.02822h173.3599a8.01411,8.01411,0,0,1,0,16.02822Z'
        transform='translate(-223.06266 -98.08587)'
        fill={primaryColor}
      />
      <path
        d='M703.79234,336.71073H574.44224a3.8408,3.8408,0,0,0-3.83984,3.84v100.95a3.84075,3.84075,0,0,0,3.83984,3.84h129.3501a3.84076,3.84076,0,0,0,3.83984-3.84v-100.95A3.84081,3.84081,0,0,0,703.79234,336.71073Z'
        transform='translate(-223.06266 -98.08587)'
        fill='#e6e6e6'
      />
      <path
        d='M609.92406,398.70111a34.087,34.087,0,0,1-8.804,23.076c-5.656,6.20712-14.07618,10.3236-22.57327,8.62043-7.82416-1.56829-14.18219-8.4067-13.389-16.6795a12.356,12.356,0,0,1,15.2668-11.09515c7.43265,1.92885,10.39415,12.64095,4.20051,17.669-1.4862,1.2065-3.62136-.90359-2.12132-2.12132,4.0944-3.32385,2.8295-10.5954-2.11244-12.419-5.75371-2.12311-11.84978,2.44324-12.26355,8.32554-.49057,6.97428,4.85221,12.22646,11.40422,13.463,7.08789,1.3377,14.11532-2.29,18.91808-7.29718a30.95507,30.95507,0,0,0,8.474-21.54183,1.5009,1.5009,0,0,1,3,0Z'
        transform='translate(-223.06266 -98.08587)'
        fill='#2f2e41'
      />
      <circle cx='416.15529' cy='266.1673' r='53.51916' fill={primaryColor} />
      <path
        d='M636.47981,387.08916l-.05566-2c3.7207-.10352,7.001-.33692,9.46582-2.1377a6.14794,6.14794,0,0,0,2.38134-4.52832,3.51432,3.51432,0,0,0-1.15283-2.89453c-1.63623-1.38184-4.269-.93457-6.188-.05469l-1.65478.75879,3.17334-23.19043,1.98144.27149-2.69922,19.72656c2.60743-.7666,5.02344-.43652,6.67823.96094a5.471,5.471,0,0,1,1.86035,4.49218,8.13264,8.13264,0,0,1-3.2002,6.07325C643.90266,386.88115,639.78694,386.99638,636.47981,387.08916Z'
        transform='translate(-223.06266 -98.08587)'
        fill='#2f2e41'
      />
      <rect
        x='431.16715'
        y='256.92907'
        width='10.77148'
        height='2'
        fill='#2f2e41'
      />
      <rect
        x='397.16715'
        y='256.92907'
        width='10.77148'
        height='2'
        fill='#2f2e41'
      />
      <path
        d='M609.57212,445.34074a53.00636,53.00636,0,0,1,12.89014-5.93,8.56789,8.56789,0,0,1,.02-4.71,9.42609,9.42609,0,0,1,9.12988-6.63h13.04a9.45955,9.45955,0,0,1,9.15039,6.64,8.532,8.532,0,0,1,.01953,4.7,53.16732,53.16732,0,0,1,12.89014,5.93Z'
        transform='translate(-223.06266 -98.08587)'
        fill='#2f2e41'
      />
      <path
        d='M700.52232,344.39072a11.57143,11.57143,0,0,0-3.52979-2.87,8.36739,8.36739,0,0,0-3.8501-.95,8.77158,8.77158,0,0,0-5.10986,1.72c-4.07031,2.88-6.89014,9.09-6.89014,16.28,0,9.02,4.43995,16.5,10.21,17.80005a8.25321,8.25321,0,0,0,1.79.2c6.60987,0,12-8.07,12-18C705.14243,352.81077,703.33238,347.68076,700.52232,344.39072Z'
        transform='translate(-223.06266 -98.08587)'
        fill='#3f3d56'
      />
      <path
        d='M590.6024,341.86076h-.00977a8.57836,8.57836,0,0,0-4.4502-1.29,8.36738,8.36738,0,0,0-3.85009.95,11.57143,11.57143,0,0,0-3.52979,2.87l-.01025.01c-2.79981,3.29-4.60987,8.42-4.60987,14.17,0,7.76,3.27979,14.38,7.87989,16.91a8.54175,8.54175,0,0,0,4.12011,1.09,7.72431,7.72431,0,0,0,.96-.06h.00976c6.16016-.74,11.03027-8.5,11.03027-17.94C598.14243,351.01072,595.01255,344.52073,590.6024,341.86076Z'
        transform='translate(-223.06266 -98.08587)'
        fill='#3f3d56'
      />
      <path
        d='M582.77242,373.76a1.50127,1.50127,0,0,0,1.42151-1.98,58.49864,58.49864,0,1,1,112.68726-6.5747,1.50006,1.50006,0,0,0,2.93554.61914A61.50091,61.50091,0,1,0,581.35116,372.739,1.50077,1.50077,0,0,0,582.77242,373.76Z'
        transform='translate(-223.06266 -98.08587)'
        fill='#3f3d56'
      />
      <path
        d='M666.10324,329.57746c2.11924,2.89278,1.07447,6.79121-1.15837,9.28528-2.90548,3.24541-7.53877,3.45016-11.5618,2.8478-4.51431-.67591-9.3026-2.7909-13.87293-1.3656-3.89537,1.2148-6.67418,4.74793-10.7211,5.63537-3.589.787-7.88081-.25477-9.139-4.08016-.60459-1.83823,2.29142-2.6261,2.89284-.79751.81395,2.47478,4.32865,2.42543,6.34145,1.74012,3.22689-1.09867,5.71374-3.77105,8.8854-5.04749,3.73933-1.50491,7.79621-.82549,11.60323.03181,3.58831.808,7.718,2.006,11.29267.49665,2.64515-1.1169,4.74985-4.635,2.84717-7.23211-1.14219-1.5591,1.45985-3.05738,2.59042-1.51416Z'
        transform='translate(-223.06266 -98.08587)'
        fill='#2f2e41'
      />
      <path
        d='M874.932,513.49157H684.63034a8.01411,8.01411,0,1,1,0-16.02823H874.932a8.01412,8.01412,0,0,1,0,16.02823Z'
        transform='translate(-223.06266 -98.08587)'
        fill='#e6e6e6'
      />
      <path
        d='M907.99023,540.5392H684.63034a8.01412,8.01412,0,1,1,0-16.02823H907.99023a8.01412,8.01412,0,1,1,0,16.02823Z'
        transform='translate(-223.06266 -98.08587)'
        fill='#e6e6e6'
      />
      <path
        d='M874.932,610.705H684.63034a8.01411,8.01411,0,1,1,0-16.02822H874.932a8.01411,8.01411,0,1,1,0,16.02822Z'
        transform='translate(-223.06266 -98.08587)'
        fill='#e6e6e6'
      />
      <path
        d='M907.99023,637.75267H684.63034a8.01411,8.01411,0,1,1,0-16.02823H907.99023a8.01411,8.01411,0,1,1,0,16.02823Z'
        transform='translate(-223.06266 -98.08587)'
        fill='#e6e6e6'
      />
      <circle cx='386.2497' cy='420.61448' r='34' fill='#e6e6e6' />
      <circle cx='386.2497' cy='518.61448' r='34' fill='#e6e6e6' />
      <path
        d='M874.932,708.705H684.63034a8.01411,8.01411,0,1,1,0-16.02822H874.932a8.01411,8.01411,0,1,1,0,16.02822Z'
        transform='translate(-223.06266 -98.08587)'
        fill='#e6e6e6'
      />
      <path
        d='M907.99023,735.75267H684.63034a8.01411,8.01411,0,1,1,0-16.02823H907.99023a8.01411,8.01411,0,1,1,0,16.02823Z'
        transform='translate(-223.06266 -98.08587)'
        fill='#e6e6e6'
      />
      <circle cx='386.2497' cy='616.61448' r='34' fill='#e6e6e6' />
    </svg>
  )
}

export default Empty