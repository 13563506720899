import React, { useState } from 'react'
import { Button, message } from 'antd'
import { useDispatch } from 'react-redux'
import { setFormData } from '../../../data/store/formSlice/index'
import { getURLSearchParams } from '../../../data/common'
import ShowSuccess from '../ShowSuccess'

const HandleFinalSubmit = ({ payload, formData }) => {
  const [formLoading, setFormLoading] = useState(false)
  const [showSuccessMessage, setShowSuccessMessage] = useState(false)
  const [defaultMessage, setDefaultMessage] = useState('')
  const dispatch = useDispatch()

  const handleFinalSubmit = async () => {
    if (
      !payload ||
      !payload.customFormData ||
      !payload.customFormData.formData
    ) {
      console.error('Form data is missing or incorrectly structured in payload')
      return
    }

    const dataToSend = {}

    // Iterate through sections if formData exists
    payload.customFormData.formData.forEach((section) => {
      const sectionData = {}
      section.data.forEach((field) => {
        const { name, defaultValue, value } = field.props
        sectionData[name] = value || defaultValue
      })
      dataToSend[section.sectionName] = sectionData
    })

    // Collect URL parameters
    const urlParams = getURLSearchParams(window.location.href)
    const sessionId = urlParams.get('sessionId')
    const uniqueId = urlParams.get('uniqueId')
    dataToSend.sessionId = sessionId
    dataToSend.uniqueId = uniqueId

    const FORM_SUBMIT_DATA =
      `${process.env.REACT_APP_BASE_URL}/brand/indiafirst-iva/dynamicform/submitformdetails`
    setFormLoading(true)

    try {
      const response = await fetch(FORM_SUBMIT_DATA, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(dataToSend),
      })

      console.log('Response:', response)

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`)
      }

      const data = await response.json()
      console.log('Response JSON:', data)
      const { defaultMessage } = data

      setDefaultMessage(
        defaultMessage || 'Your data has been submitted successfully!'
      )
      setShowSuccessMessage(true)
      localStorage.removeItem("savedFormData")

      const waMeUrl = payload.waMeUrl
      if (waMeUrl) {
        setTimeout(() => {
          window.open(waMeUrl, '_blank')
        }, 2000)
      }
    } catch (error) {
      setFormLoading(false)
      message.error("Something went wrong")
    }
  }

  return (
    <div className='final-submit-container'>
      <Button
        className='final-submit-button'
        type='primary'
        style={{
          backgroundColor: process.env.REACT_APP_PRIMARY_COLOR || '#1890ff',
        }}
        onClick={handleFinalSubmit}
        loading={formLoading}
      >
        Final Submit
      </Button>
      {showSuccessMessage && <ShowSuccess message={defaultMessage} />}
    </div>
  )
}

export default HandleFinalSubmit
