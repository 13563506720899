import React, { useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import {
  Typography,
  Card,
  Row,
  Col,
  Tooltip,
  Select,
  Button,
  Input,
  InputNumber,
  DatePicker,
  message,
  Radio,
  Tag,
} from "antd"
import { EditOutlined, InfoCircleOutlined } from "@ant-design/icons"

import dayjs from "dayjs"

import { IMG_EMPTY, IMG_ERROR } from "../data/assets/index"

import {
  setFormData,
  setSessionId,
  setUniqueId,
  formDataSubmit,
} from "../data/store/formSlice"
import { getFormDetails } from "../data/store/formSlice/api"

import { getURLSearchParams } from "../data/common"
import logo from "../data/assets/indiafirstLogo.png"
import DynamicForm from "./component/DynamicForm/DynamicForm"

import ShowMessage from "./component/ShowMessage"
import ShowSuccess from "./component/ShowSuccess"
import HandleFinalSubmit from "./component/FinalSubmit"
import SpinnerLoader from "./component/SpinnerLoader"

import "../data/styles/app.scss"
import "../data/styles/common.scss"
import "../data/styles/variables.scss"

const { Title, Text } = Typography

const AppDisplay = () => {
  const dispatch = useDispatch()
  const formData = useSelector((state) => state.form.formData || {})
  const [submitLoading, setFormLoading] = useState(false)
  const [defaultMessage, setDefaultMessage] = useState("")
  const [loading, setLoading] = useState(true)
  const [showLoader, setShowLoader] = useState(true)
  const [fieldErrors, setFieldErrors] = useState({})
  const { TextArea } = Input

  const [payload, setPayload] = useState(null)

  const [editSectionIndex, setEditSectionIndex] = useState(null)
  const [backupPayload, setBackupPayload] = useState(null)
  const [fetchError, setFetchError] = useState(false)
  const [showSuccessMessage, setShowSuccessMessage] = useState(false)
  const [isValid, setIsValid] = useState(false)
  const [allValid, setAllValid] = useState(true)

  useEffect(() => {
    const urlParams = getURLSearchParams(window.location.href)

    const sessionId = urlParams.get("sessionId")
    const uniqueId = urlParams.get("uniqueId")
    console.log(sessionId, uniqueId, "params")

    if (sessionId) {
      dispatch(setSessionId(sessionId))
    }
    if (uniqueId) {
      dispatch(setUniqueId(uniqueId))
    }
  }, [dispatch])

  useEffect(() => {
    const res = localStorage.getItem("savedFormData")
    if (res) {
      const savedData = JSON.parse(res)
      setPayload((prevPayload) => {
        if (
          !prevPayload ||
          !prevPayload.customFormData ||
          !prevPayload.customFormData.formData
        ) {
          return prevPayload
        }

        const updatedFormData = prevPayload.customFormData.formData.map(
          (section) => {
            section.data = section.data.map((field) => {
              field.props.value =
                savedData[field.props.name] !== undefined
                  ? savedData[field.props.name]
                  : field.props.value
              return field
            })
            return section
          }
        )

        return {
          ...prevPayload,
          customFormData: {
            ...prevPayload.customFormData,
            formData: updatedFormData,
          },
        }
      })
    }
  }, [])

  const applyDependencyLogic = (
    tobeChangeField,
    dependingField,
    filteringKey = "value"
  ) => {
    const { props, dependableValue } = tobeChangeField
    const { props: dependingProps } = dependingField

    switch (tobeChangeField.type) {
      case "text":
        if (!dependableValue) return { ...tobeChangeField }

        let tobeUpdatedValue = dependableValue[dependingProps?.[filteringKey]]

        return {
          ...tobeChangeField,
          props: {
            ...props,
            value: tobeUpdatedValue,
          },
        }

      case "select": {
        const filteredOptions = props.options.map((option) => {
          console.log(
            dependingProps?.[filteringKey],
            filteringKey,
            "dependingProps?.[filteringKey"
          )

          if (
            !option?.parent ||
            dependingProps?.[filteringKey] === option.parent
          ) {
            return { ...option, notVisible: false }
          }
          return { ...option, notVisible: true }
        })

        const validValues = filteredOptions
          .filter((option) => !option.notVisible)
          .map((option) => option.value)

        return {
          ...tobeChangeField,
          props: {
            ...props,
            options: filteredOptions,
            ...(validValues.length > 0 && {
              value: validValues[0],
            }),
          },
        }
      }

      case "checkbox": {
        const validOptions = props.options?.filter(
          (option) => option?.parent === dependingProps?.[filteringKey]
        )

        const validValues = validOptions.map((option) => option.value)
        const existingValues = Array.isArray(props.value)
          ? props.value.filter((value) => validValues.includes(value))
          : []
        const newValues = [...new Set([...existingValues, ...validValues])]

        return {
          ...tobeChangeField,
          props: {
            ...props,
            value: newValues,
          },
        }
      }

      case "radio":
        const toBeSelectedOption = props.options?.find(
          (option) => option?.parent === dependingProps?.[filteringKey]
        )

        return {
          ...tobeChangeField,
          props: {
            ...props,
            value: toBeSelectedOption?.value,
          },
        }

      case "textarea":
        return {
          ...tobeChangeField,
          props: {
            ...props,
            value: dependableValue[dependingProps?.[filteringKey]],
          },
        }
      case "input":
        return {
          ...tobeChangeField,
          props: {
            ...props,
            value: dependableValue[dependingProps?.[filteringKey]],
          },
        }
      case "inputNumber":
        return {
          ...tobeChangeField,
          props: {
            ...props,
            value: dependableValue[dependingProps?.[filteringKey]],
          },
        }

      case "date":
        return {
          ...tobeChangeField,
          props: {
            ...props,
            value: dependableValue[dependingProps?.[filteringKey]],
          },
        }

      default:
        return { ...tobeChangeField }
    }
  }

  const fetchPayload = async () => {
    setLoading(true)
    try {
      const response = await getFormDetails()
      setPayload(response)
      setFetchError(false)
    } catch (error) {
      setFetchError(true)
    } finally {
       setTimeout(() => setLoading(false), 1500)
    }
  }

  useEffect(() => {
    fetchPayload()
    const loaderTimeout = setTimeout(() => setShowLoader(false), 1500)
    return () => clearTimeout(loaderTimeout)
  }, [])

  if (showLoader) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <SpinnerLoader />
      </div>
    )
  }

  if (fetchError) {
    return (
      <div
        className="ori-flex ori-flex-column ori-flex-center"
        style={{ marginTop: "50px" }}
      >
        <IMG_ERROR />
        <h2 style={{ marginTop: "20px", color: "#555" }}>
          Something went wrong
        </h2>
        <p style={{ color: "#888" }}>
          Please try again or click the button below to fetch the data.
        </p>
        <button
          onClick={fetchPayload}
          className="ori-btn"
          style={{
            marginTop: "20px",
            backgroundColor: process.env.REACT_APP_PRIMARY_COLOR || "#1890ff",
            color: "#fff",
            border: "none",
            padding: "10px 20px",
            borderRadius: "5px",
            cursor: "pointer",
          }}
        >
          Retry
        </button>
      </div>
    )
  }

  if (
    !payload ||
    !payload.customFormData ||
    !payload.customFormData.formData ||
    payload.customFormData.formData.length === 0 ||
    payload.defaultMessage ||
    !payload.isSuccess
  ) {
    return (
      <div className="centered-container">
        <ShowMessage
          ImgComponent={IMG_EMPTY}
          message={payload?.defaultMessage}
          width={400}
          btn_text="Go Back to Whatsappp"
          btn_props={{
            onClick: () => {
              window.open(payload.waMeUrl, "_blank")
            },
          }}
        />
      </div>
    )
  }

  const handleEditClick = (sectionIdx) => {
    setEditSectionIndex(sectionIdx)
    setBackupPayload(JSON.parse(JSON.stringify(payload)))
  }

  const handleFieldChange = (sectionIdx, fieldName, value, changedField) => {
    const updatedSections = [...payload.customFormData.formData]
    const section = updatedSections[sectionIdx]

    if (section?.data) {
      const fieldIndex = section.data.findIndex(
        (field) => field.props.name === fieldName
      )

      if (fieldIndex !== -1) {
        const field = section?.data?.[fieldIndex]
        field.props.value = value
        const regex = field?.props?.regex ? new RegExp(field.props.regex) : null
        if (regex && !regex.test(value) && value !== "") {
          setFieldErrors((prev) => ({
            ...prev,
            [fieldName]: field?.props?.regexMessage || "Invalid format",
          }))
        } else {
          setFieldErrors((prev) => {
            const updatedErrors = { ...prev }
            delete updatedErrors[fieldName]
            return updatedErrors
          })
        }

        section.data = section.data.map((field) => {
          if (field.dependentOn === fieldName) {
            return applyDependencyLogic(field, changedField)
          }
          return field
        })

        setPayload({
          ...payload,
          customFormData: {
            ...payload.customFormData,
            formData: updatedSections,
          },
        })
        const totalValid = validateTotalSum(section)
        console.log("totalValid", totalValid)

        setAllValid(totalValid)
      }
    }
  }

  const validateTotalSum = (section) => {
    if (!section.totalLimit) return true

    const totalSum = section.data.reduce((sum, field) => {
      const value =
        field?.props?.value !== undefined
          ? field?.props?.value
          : field?.props?.defaultValue

      if (field?.includeSum && value && !isNaN(value)) {
        return sum + parseFloat(value)
      }
      return sum
    }, 0)

    if (totalSum !== section.totalLimit) {
      return false
    }

    return true
  }

  const handleCancelClick = () => {
    if (backupPayload) {
      setPayload(backupPayload)
      setBackupPayload(null)
    }
    setEditSectionIndex(null)
  }

  const validateRequiredFields = (sectionIdx) => {
    const section = payload?.customFormData?.formData[sectionIdx]
    if (!section) return true

    let isValid = true

    section.data.forEach((field) => {
      const fieldValue = field.props.value

      // Check if the field is required and has a value
      if (field?.props?.required && fieldValue === "") {
        // message.error(`Please fill all the required fields`)
        isValid = false
      }

      if (field?.props?.regex && fieldValue) {
        const regex = new RegExp(field.props.regex)
        if (!regex.test(fieldValue)) {
          console.error(
            `Validation failed for field: ${field.label}. ${field.props.regexMessage}`
          )
          isValid = false
        }
      }
    })
    return isValid
  }

  const handleSaveClick = () => {
    let allValid = true
    let totalValid = true

    payload?.customFormData?.formData?.forEach((section, sectionIdx) => {
      if (!validateRequiredFields(sectionIdx)) {
        allValid = false
      }
    })

    payload?.customFormData?.formData?.forEach((section) => {
      if (!validateTotalSum(section)) {
        totalValid = false
      }
    })

    if (!totalValid) {
      payload?.customFormData?.formData.forEach((section) => {
        if (section.data.some((field) => field?.includeSum)) {
          const totalSum = section.data
            .filter((field) => field?.includeSum)
            .reduce((sum, field) => sum + (field.props.value || 0), 0)

          if (totalSum !== section.totalLimit) {
            const fieldsThatNeedAdjustment = section.data
              .filter((field) => field?.includeSum)
              .map((field) => field.label)

            message.error(
              `The total sum of ${fieldsThatNeedAdjustment.join(
                ", "
              )} should be equal to ${section.totalLimit}`
            )
          }
        }
      })
      return
    }
    if (allValid) {
      const savedData = {}
      payload.customFormData.formData.forEach((section) => {
        section.data.forEach((field) => {
          savedData[field.props.name] = field.props.value
        })
      })
      localStorage.setItem("savedFormData", JSON.stringify(savedData))
      message.success("Data saved successfully!")
      setEditSectionIndex(null)
      setBackupPayload(null)
    } else {
      message.error("Validation failed. Please check the fields.")
    }
  }

  const renderEditableField = (oldField, value, onChange, allFields) => {
    let field = oldField

    // if (field.dependentOn && allFields) {
    //   const dependingField = allFields.find(
    //     (curr) => curr.props.name === field.dependentOn
    //   )

    //   if (dependingField)
    //     field = applyDependencyLogic(field, dependingField, 'defaultValue')
    //     console.log(field, 'dependingField')
    // }

    const errorMessage = fieldErrors[field.props.name]
    const isError = errorMessage !== undefined

    const errorMsgProps = {
      style: {
        color: "red",
        fontSize: "12px",
        marginTop: "5px",
      },
    }

    switch (field.type) {
      case "date":
        return (
          <div>
            <DatePicker
              value={
                value
                  ? dayjs(value, field.props.format || "YYYY-MM-DD")
                  : undefined
              }
              disabledDate={(c) =>
                c && field.disabledTimestamp && field.beforeDisabledTimestamp
                  ? c.valueOf() > field.disabledTimestamp ||
                    field.beforeDisabledTimestamp > c.valueOf()
                  : field.disabledTimestamp
                  ? c.valueOf() > field.disabledTimestamp
                  : field.beforeDisabledTimestamp
                  ? field.beforeDisabledTimestamp > c.valueOf()
                  : null
              }
              onChange={(date, dateString) => onChange(dateString)}
              format={field.props.format || "YYYY-MM-DD"}
              placeholder={field.props.placeholder || "Select a date"}
              disabled={field.props.disable}
              inputReadOnly
            />
            {isError && <div {...errorMsgProps}>{errorMessage}</div>}
          </div>
        )
      case "select":
        return (
          <div>
            <Select
              value={value}
              onChange={onChange}
              showSearch
              style={{ width: "100%" }}
              size="default"
              placeholder={field.props.placeholder || "Select an option"}
              optionFilterProp="children"
              filterOption={(input, option) =>
                option?.children.toLowerCase().includes(input.toLowerCase())
              }
              disabled={field.props.disable}
            >
              {field.props.options
                .filter((option) => !option.notVisible)
                .map((option) => (
                  <Select.Option key={option.value} value={option.value}>
                    {option.label}
                  </Select.Option>
                ))}
            </Select>
            {isError && <div {...errorMsgProps}>{errorMessage}</div>}
          </div>
        )
      case "input":
        return (
          <div>
            <Input
              {...field.props}
              type={field.props.type || "number"}
              value={
                value ||
                (typeof field?.props?.defaultValue === "object"
                  ? field?.props?.defaultValue.label
                  : field.props.value === ""
                  ? field.props.value
                  : field?.props?.defaultValue)
              }
              onChange={(e) => onChange(e.target.value)}
            />
            {isError && (
              <div
                {...errorMsgProps}
                style={{ fontSize: "12px", color: "red" }}
              >
                {errorMessage}
              </div>
            )}
          </div>
        )
      case "text":
        return (
          <div>
            <Input
              value={value}
              onChange={(e) => onChange(e.target.value)}
              placeholder={field.props.placeholder || "Enter text"}
              disabled={field.props.disable}
            />
            {isError && <div {...errorMsgProps}>{errorMessage}</div>}
          </div>
        )
      case "textarea":
        return (
          <div>
            <TextArea
              value={value}
              onChange={(e) => onChange(e.target.value)}
              placeholder={field.props.placeholder || "Enter text"}
              rows={field.props.rows || 4}
              disabled={field.props.disable}
            />
            {isError && <div {...errorMsgProps}>{errorMessage}</div>}
          </div>
        )
      case "inputNumber":
        return (
          <div>
            <InputNumber
              value={value}
              min={field.props.min}
              max={field.props.max}
              formatter={field.props.formatter}
              parser={field.props.parser}
              onChange={(value) => onChange(value)}
              disabled={field.props.disable}
            />
            {isError && <div {...errorMsgProps}>{errorMessage}</div>}
          </div>
        )
      case "checkbox":
        return (
          <div>
            {field.props.options.map((option) => (
              <label key={option.value}>
                <input
                  className="custom-checkbox-group"
                  type="checkbox"
                  checked={value?.includes(option.value)}
                  onChange={(e) => {
                    const newValue = e.target.checked
                      ? [...(value || []), option.value]
                      : value.filter((val) => val !== option.value)
                    onChange(newValue)
                  }}
                  disabled={field.props.disable}
                />
                {option.label}
              </label>
            ))}
            {isError && <div {...errorMsgProps}>{errorMessage}</div>}
          </div>
        )
      case "radio":
        return (
          <>
            <Radio.Group
              optionType="button"
              buttonStyle="solid"
              className="custom-radio-group"
              value={value}
              onChange={(e) => onChange(e.target.value)}
              disabled={field.props.disable}
            >
              {field.props.options.map((option) => (
                <Radio.Button key={option.value} value={option.value}>
                  {option.label}
                </Radio.Button>
              ))}
            </Radio.Group>
            {isError && { errorMessage }}
          </>
        )

      default:
        return <Text>{value}</Text>
    }
  }

  const renderDisplaySection = (section, sectionIdx) => {
    const isEditing = editSectionIndex === sectionIdx
    const isValid = validateRequiredFields(sectionIdx)

    const sectionColor = process.env.REACT_APP_SECTION_COLOR || "#f1f3f6"
    return (
      <Card
        key={sectionIdx}
        title={
          <div style={{ display: "flex", alignItems: "center" }}>
            <Text style={{ fontSize: "20px" }}>{section.sectionName}</Text>
            {section.tooltip && (
              <Tooltip title={section.tooltip}>
                <InfoCircleOutlined
                  style={{
                    marginLeft: 8,
                    color: "#1890ff",
                    fontSize: "16px",
                    cursor: "pointer",
                  }}
                />
              </Tooltip>
            )}
          </div>
        }
        extra={
          isEditing && section.totalLimit ? (
            <Tag style={{ fontSize: "16px", padding: "5px 10px" }}>
              Total Limit: {section.totalLimit}
            </Tag>
          ) : section.isEdit && !isEditing ? (
            <EditOutlined
              onClick={() => handleEditClick(sectionIdx)}
              style={{
                cursor: "pointer",
                fontSize: "24px",
              }}
            />
          ) : null
        }
        className="section-card"
        style={{
          backgroundColor: sectionColor,
          borderRadius: "8px",
          boxShadow: "0 2px 6px rgba(0, 0, 0, 0.1)",
        }}
      >
        <Row gutter={[16, 16]}>
          {section.data.map((field, idx) => {
            const fieldValue =
              field.props.value ||
              (typeof field?.props?.defaultValue === "object"
                ? field?.props?.defaultValue.label
                : field.props.value === ""
                ? field.props.value
                : field?.props?.defaultValue) ||
              formData?.formData?.[field.props.name]

            return (
              <Col
                xs={24}
                sm={24}
                md={isEditing ? 12 : 24}
                lg={isEditing ? 12 : 24}
                xl={isEditing ? 12 : 24}
                key={idx}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: isEditing ? "column" : "row",
                    alignItems: isEditing ? "flex-start" : "center",
                    justifyContent: isEditing ? "flex-start" : "space-between",
                    gap: isEditing ? "4px" : "8px",
                  }}
                >
                  {/* Label */}
                  <div
                    style={{
                      flex: isEditing ? "none" : "0 0 30%",
                      fontSize: "16px",
                    }}
                    className="responsive-label"
                  >
                    <Text>
                      {field.label}
                      {isEditing && field.props.required && (
                        <Text
                          style={{
                            color: "red",
                            fontWeight: "bold",
                            marginLeft: "4px",
                          }}
                        >
                          *
                        </Text>
                      )}
                    </Text>
                    {field.description && (
                      <div
                        style={{
                          fontSize: "10px",
                          color: "#888",
                          marginTop: "1px",
                        }}
                      >
                        {field.description}
                      </div>
                    )}
                  </div>

                  {/* Field */}
                  <div
                    style={{
                      flex: "1",
                      width: isEditing ? "100%" : "auto",
                      fontSize: "16px",
                    }}
                    className="responsive-field"
                  >
                    {isEditing ? (
                      renderEditableField(
                        field,
                        fieldValue,
                        (value) =>
                          handleFieldChange(
                            sectionIdx,
                            field.props.name,
                            value,
                            field
                          ),
                        section.data
                      )
                    ) : (
                      <Text
                        style={{
                          textAlign: "right",
                          fontSize: "16px",
                          fontWeight: "bold",
                          width: "100%",
                          display: "block",
                        }}
                        className="responsive-field-text"
                      >
                        {Array.isArray(fieldValue)
                          ? fieldValue
                              .map((val) => {
                                const option = field.props.options.find(
                                  (option) => option.value === val
                                )
                                return option?.label || val
                              })
                              .join(", ")
                          : fieldValue || field?.props?.defaultValue}
                      </Text>
                    )}
                  </div>
                </div>
              </Col>
            )
          })}
        </Row>

        {isEditing && (
          <div
            className="button-group"
            style={{
              marginTop: "20px",
            }}
          >
            <Button
              type="primary"
              onClick={handleSaveClick}
              style={{
                marginRight: 10,
                backgroundColor:
                  process.env.REACT_APP_PRIMARY_COLOR || "$color-primary",
                borderColor:
                  process.env.REACT_APP_PRIMARY_COLOR || "$color-primary",
              }}
              disabled={!isValid}
            >
              Save
            </Button>
            <Button onClick={handleCancelClick}>Cancel</Button>
          </div>
        )}
      </Card>
    )
  }

  return (
    <>
      <div className="app-container">
        <div className="logo-container">
          <img src={logo} alt="Logo" className="logo" />
        </div>
        <Title level={2} className="dynamic-form-title">
          {payload.customFormData.displayType === "form"
            ? payload.customFormData.header || "Form"
            : payload.customFormData.header || "View Submitted Data"}
        </Title>

        {payload.customFormData.displayType === "form" ? (
          <DynamicForm
            dynamicPayload={payload}
            payload={{
              sections: payload.customFormData.formData.map((section) => ({
                title: section.sectionName,
                description: section.description,
                totalLimit: section.totalLimit,
                fields: section.data.map((field) => ({
                  ...field,
                  includeSum: field.includeSum || false,
                  totalLimit: section.totalLimit || null,
                })),
              })),
              waMeUrl: payload.waMeUrl,
            }}
            initialData={formData}
            onSubmit={(data) => {
              console.log("Submitted Data:", data)
              dispatch(setFormData(data))
            }}
          />
        ) : (
          payload.customFormData?.formData.map((section, idx) =>
            renderDisplaySection(section, idx)
          )
        )}

        {payload.customFormData.displayType !== "form" && (
          <HandleFinalSubmit payload={payload} formData={formData} />
        )}
      </div>

      {showSuccessMessage && <ShowSuccess message={defaultMessage} />}
    </>
  )
}
export default AppDisplay
